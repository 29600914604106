
import { Component } from 'vue-property-decorator';
import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';
import RouterLinkData from '@/components/Op/Layout/types';
import PwrVue from '@/components/PwrVue';
import OpLayout from '@/views/Layouts/OpLayout.vue';
import OpNavBar from '@/components/Op/Layout/OpNavBar.vue';
import OpNavigation from '@/components/Op/Layout/OpNavigation.vue';

@Component({
  components: { OpNavigation, OpNavBar, OpLayout }
})
export default class AdminLayout extends PwrVue {
  private navItems: RouterLinkData[] = [
    {
      name: SiteRoutesNames.OFFERS_FOR_TEACHERS,
      displayedName: this.$t('layouts.pwr.administration.navbar.actions.home') as string,
      authorized: true
    },
    {
      name: AdminRoutesNames.OFFERS_ADMINISTRATION,
      displayedName: this.$t('layouts.pwr.administration.navbar.actions.offers') as string,
      authorized: true
    },
    {
      name: AdminRoutesNames.COMPETITIONS_RESULTS,
      displayedName: this.$t('layouts.pwr.administration.navbar.actions.competitions') as string,
      visible: false,
      authorized: true
    },
    {
      name: AdminRoutesNames.USERS_AND_PERMS_ADMINISTRATION,
      displayedName: this.$t('layouts.pwr.administration.navbar.actions.users') as string,
      visible: false,
      authorized: true
    }
  ];
}
